<template>
  <div class="wrapper">
    <div class="nav nav-pills">
      <router-link
        to="/repairs/repairs-orders-list/not-finished"
        data-text="список заявок в работе"
        class="nav-link"
        active-class="active"
        >список заявок в работе</router-link
      >
      <router-link
        to="/repairs/repairs-orders-list/finished"
        data-text="список выполненных заявок"
        class="nav-link"
        active-class="active"
        >список выполненных заявок</router-link
      >
    </div>
    <router-view class="view" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "RepairsOrders",
  computed: {
    ...mapGetters(["PATH"])
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 5px;
}
.active {
  color: #fff;
  background: #007bff;
  cursor: default;
  text-decoration: none;
}
</style>
